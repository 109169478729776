<template>
  <section class="main-programs">
    <div class="main-title">
      <h2>
        <span>мы предлагаем широкий выбор программ</span> для&nbsp;всех возрастов и интересов
      </h2>
    </div>

    <div class="main-programs__buttons">
      <button
        v-for="(button, index) in buttons"
        :key="index"
        :class="['m-btn', { active: activeButtonIndex === index }]"
        @click="activeButtonIndex = index"
      >
        {{ button.title }}
      </button>
    </div>

    <div class="main-programs__programs">
      <div
        v-for="(program, index) in buttons[activeButtonIndex].programs"
        :key="index"
        class="main-programs__program"
        :style="{ backgroundImage: `url(/v2/main/programs/${program.image}.svg)` }"
        tabindex="0"
        role="button"
        @click="programClick(program)"
      >
        <h4 v-html="program.title" />
        <p v-html="program.text" />
        <button :class="`m-btn main-programs__program_arrow-${program.arrow}`">
          <img
            src="/v2/main/programs/arrow.svg"
            alt="->"
            loading="lazy"
          >
          <span>{{ program.btnText }}</span>
        </button>

        <div
          v-if="program.discount"
          class="main-programs__program_discount"
        >
          <span>-30%</span>
          <p>для учеников школы</p>
        </div>
      </div>

      <div class="main-programs__form">
        <p>мы поможем подобрать подходящее обучение</p>

        <form-name-field
          ref="nameField"
          v-model="nameInput"
          class="form__input"
          placeholder="как вас зовут?"
          round-border
        />

        <form-phone-field
          ref="phoneField"
          class="form__input form__input_phone"
          :errors-list="submitError"
          :error="validForm === false"
          round-border
          placeholder="ваш номер телефона"
          @updatePhone="phoneInput = $event"
        />

        <form-email-field
          ref="emailField"
          v-model="emailInput"
          class="form__input"
          :submit-error="submitError"
          round-border
          placeholder="ваша почта"
          @clearSubmitError="clearSubmitError"
        />

        <!-- eslint-disable -->
        <span>
          нажимая на кнопку, вы соглашаетесь
          с&nbsp;<nuxt-link
            to="/privacy"
            target="_blank"
          >условиями обработки данных</nuxt-link>
          в&nbsp;соответствии
          с&nbsp;<nuxt-link
            to="/privacy"
            target="_blank"
          >политикой конфиденциальности</nuxt-link>
        </span>
        <!-- eslint-enable -->

        <button
          class="m-btn main-programs__form_submit"
          @click="submit"
        >
          подобрать обучение
        </button>

        <button
          class="m-btn main-programs__form_btn"
          @click="storeOpenModal(MODAL_TYPES.suitableTraining)"
        >
          подобрать обучение
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import sendLeadMixinConstructor from '~/mixins/sendLeadMixinConstructor';
import FormNameField from '~/components/v2/common/fileds/FormNameField.vue';
import FormEmailField from '~/components/v2/common/fileds/FormEmailField.vue';
import FormPhoneField from '~/components/v2/common/fileds/FormPhoneField.vue';

import { MODAL_TYPES } from '../common/AppHeader/data';

const sendLeadMixin = sendLeadMixinConstructor({
  needPhone: true,
  needEmail: true,
  needToSendTitle: true,
});

const programItems = {
  homeSchool: {
    title: 'домашняя школа',
    text: 'получите качественное образование, не&nbsp;выходя из&nbsp;дома',
    image: 'home-school',
    arrow: 'yellow',
    link: '/online-school',
    btnText: 'подробнее',
  },
  profileSchool: {
    title: 'профильная школа',
    text: 'освойте актуальные навыки и&nbsp;востребованные профессии',
    image: 'profile-school',
    arrow: 'yellow',
    link: '/school-university',
    btnText: 'подробнее',
  },
  external: {
    title: 'экстернат',
    text: 'удобный формат обучения для тех, кто&nbsp;ценит свободу и гибкость',
    image: 'external',
    arrow: 'green',
    link: '/externat',
    btnText: 'подробнее',
  },
  tutors: {
    title: 'репетиторы',
    text: 'подними свой уровень знаний и&nbsp;покори&nbsp;вершину успеха',
    image: 'tutors',
    arrow: 'violet',
    link: '/catalog/repetitor',
    btnText: 'смотреть все курсы',
  },
  ege: {
    title: 'подготовка к&nbsp;егэ',
    text: 'гарантированное повышение баллов и&nbsp;уверенность в&nbsp;результате экзаменов',
    image: 'ege',
    arrow: 'blue',
    link: '/ege',
    btnText: 'подробнее',
  },
  oge: {
    title: 'подготовка к&nbsp;огэ',
    text: 'гарантированное повышение баллов и&nbsp;уверенность в&nbsp;результате экзаменов',
    image: 'ege',
    arrow: 'blue',
    link: '/oge',
    btnText: 'подробнее',
  },
  careerGuidance: {
    title: 'профориентация',
    text: 'подготовьтесь к&nbsp;поступлению и&nbsp;сделайте выбор в&nbsp;сторону «правильной» профессии',
    image: 'career-guidance',
    arrow: 'blue',
    link: '/proforientacia',
    btnText: 'подробнее',
  },
  it: {
    title: 'it курсы',
    // eslint-disable-next-line max-len,vue/max-len
    text: 'программирование, веб-разработка, 3D- и&nbsp;геймдизайн&nbsp;— помогите ребенку стать успешным в&nbsp;IT-индустрии',
    image: 'it',
    arrow: 'green',
    link: '/catalog/programmirovanie-dlya-detej',
    btnText: 'смотреть все курсы',
  },
  languages: {
    title: 'языковые курсы',
    text: 'создайте свое будущее со знанием разных языков: английский, испанский, китайский, немецкий и другие',
    image: 'languages',
    arrow: 'pink',
    modal: MODAL_TYPES.languages,
    btnText: 'смотреть все курсы',
  },
  softSkills: {
    title: 'развитие soft&nbsp;skills',
    text: 'развивайте свои таланты, осваивай новые хобби и расширяйте кругозор',
    image: 'soft-skills',
    arrow: 'pink',
    modal: MODAL_TYPES.soft,
    btnText: 'узнать больше',
  },
  media: {
    title: 'медиа курсы',
    text: 'раскройте свой творческий потенциал: видеосъемка, создание музыки, актерское мастерство и многое другое',
    image: 'media',
    arrow: 'green',
    modal: MODAL_TYPES.skills,
    btnText: 'смотреть все курсы',
  },
  business: {
    title: 'бизнес курсы',
    text: 'отличная возможность дать своему ребенку необходимые знания и&nbsp;навыки для&nbsp;будущей карьеры',
    image: 'ege',
    arrow: 'green',
    modal: MODAL_TYPES.skills,
    btnText: 'смотреть все курсы',
  },
  digital: {
    title: 'digital курсы',
    // eslint-disable-next-line max-len,vue/max-len
    text: 'от&nbsp;видеомонтажа и&nbsp;создания подкастов до&nbsp;дизайна и&nbsp;маркетинга&nbsp;— ваш&nbsp;ребенок освоит навыки будущего',
    image: 'profile-school',
    arrow: 'green',
    modal: MODAL_TYPES.skillsGreen,
    btnText: 'смотреть все курсы',
  },
  animations: {
    title: 'школа анимации для&nbsp;детей',
    text: '',
    image: 'profile-school',
    arrow: 'green',
    modal: MODAL_TYPES.modal14,
    btnText: 'подробнее',
  },
  artists: {
    title: 'школа актерского мастерства',
    text: '',
    image: 'external',
    arrow: 'green',
    modal: MODAL_TYPES.modal14,
    btnText: 'подробнее',
  },
  models: {
    title: 'детская модельная школа',
    text: '',
    image: 'career-guidance',
    arrow: 'green',
    modal: MODAL_TYPES.modal14,
    btnText: 'подробнее',
  },
  music: {
    title: 'детская музыкальная школа',
    text: '',
    image: 'home-school',
    arrow: 'green',
    modal: MODAL_TYPES.modal14,
    btnText: 'подробнее',
  },
  dance: {
    title: 'школа танцев для&nbsp;детей',
    text: '',
    image: 'soft-skills',
    arrow: 'pink',
    modal: MODAL_TYPES.modal14,
    btnText: 'подробнее',
  },
  synergyBox: {
    title: 'synergy box',
    text: 'развитие гибких навыков и&nbsp;личностных&nbsp;качеств',
    image: 'ege',
    arrow: 'pink',
    modal: MODAL_TYPES.synergyBox,
    btnText: 'подробнее',
  },
  schoolPreparing: {
    title: 'подготовка к&nbsp;школе',
    // eslint-disable-next-line max-len,vue/max-len
    text: 'сформируйте уверенную базу для&nbsp;первого класса, чтобы&nbsp;ребенок учился хорошо и&nbsp;без&nbsp;стресса',
    image: 'soft-skills',
    arrow: 'pink',
    modal: MODAL_TYPES.preschooler,
    btnText: 'подробнее',
  },
  bilingv: {
    title: 'билингвальный детский сад',
    text: 'формируем у&nbsp;ребенка ключевые личностные и&nbsp;социальные навыки, а&nbsp;также творческие способности',
    image: 'ege',
    arrow: 'pink',
    modal: MODAL_TYPES.preschooler,
    btnText: 'подробнее',
  },
  externalAdult: {
    title: 'экстернат для&nbsp;взрослых',
    // eslint-disable-next-line max-len,vue/max-len
    text: 'пройдите школьную программу и&nbsp;получили аттестат гос. образца за&nbsp;короткий срок и&nbsp;в&nbsp;удобное время ',
    image: 'external',
    arrow: 'pink',
    link: '/externat-dlya-vzroslyh',
    btnText: 'подробнее',
  },
  college: {
    title: 'колледж синергия',
    text: 'открываем путь к востребованной профессии после 9-го или 11-го класса',
    image: 'profile-school',
    arrow: 'pink',
    discount: true,
    modal: MODAL_TYPES.discount30,
    btnText: 'подробнее',
  },
  university: {
    title: 'университет синергия',
    text: 'дистанционные и очные программы бакалавриата и магистратуры в топ-3 лучших вузов страны по версии hh.ru',
    image: 'home-school',
    arrow: 'pink',
    discount: true,
    modal: MODAL_TYPES.discount30,
    btnText: 'подробнее',
  },
  doubleDiploma: {
    title: 'двойной диплом',
    text: 'программы двойного диплома с&nbsp;международными университетами: Китая, Дубая и&nbsp;Сербии',
    image: 'career-guidance',
    arrow: 'pink',
    modal: MODAL_TYPES.secondDiploma,
    btnText: 'подробнее',
  },
  secondEducation: {
    title: 'второе высшее',
    text: 'освойте новую специальности или&nbsp;улучшите текущие знания без&nbsp;отрыва от&nbsp;работы или учебы',
    image: 'career-guidance',
    arrow: 'pink',
    modal: MODAL_TYPES.secondEducation,
    btnText: 'подробнее',
  },
};

export default {
  name: 'MainPrograms',
  components: {
    FormPhoneField,
    FormEmailField,
    FormNameField,
  },
  mixins: [sendLeadMixin],
  data: () => ({
    activeButtonIndex: 0,
    buttons: [
      {
        title: '10-11 класс',
        programs: [
          programItems.homeSchool,
          programItems.profileSchool,
          programItems.external,
          programItems.tutors,
          programItems.ege,
          programItems.careerGuidance,
          programItems.it,
          programItems.languages,
          programItems.softSkills,
        ],
      },
      {
        title: '8-9 класс',
        programs: [
          programItems.homeSchool,
          programItems.profileSchool,
          programItems.external,
          programItems.tutors,
          programItems.oge,
          programItems.careerGuidance,
          programItems.it,
          programItems.languages,
          programItems.softSkills,
        ],
      },
      {
        title: '5-7 класс',
        programs: [
          programItems.homeSchool,
          programItems.external,
          programItems.tutors,
          programItems.it,
          programItems.media,
          programItems.business,
          programItems.digital,
          programItems.languages,
          programItems.softSkills,
        ],
      },
      {
        title: '1-4 класс',
        programs: [
          programItems.tutors,
          programItems.it,
          programItems.languages,
          programItems.animations,
          programItems.artists,
          programItems.models,
          programItems.music,
          programItems.dance,
          programItems.synergyBox,
        ],
      },
      {
        title: 'дошкольники',
        programs: [
          programItems.schoolPreparing,
          { ...programItems.tutors, link: null, modal: MODAL_TYPES.preschooler },
          programItems.languages,
          programItems.bilingv,
        ],
      },
      {
        title: 'взрослым',
        programs: [
          programItems.externalAdult,
          programItems.college,
          programItems.university,
          programItems.doubleDiploma,
          programItems.secondEducation,
        ],
      },
    ],
    MODAL_TYPES,
  }),

  methods: {
    ...mapActions('appHeaderModals', {
      storeOpenModal: 'openModal',
    }),

    programClick({ link, modal }) {
      if (link) {
        this.$router.push(link);
      }
      if (modal) {
        this.storeOpenModal(modal);
      }
    },
  },
};
</script>

<style scoped lang="scss">

.main-programs {
  .main-title {
    margin-bottom: calc(var(--scale) * 40);

    @include media-down($size-tablet) {
      margin-bottom: calc(var(--scale) * 24);
    }

    @include media-down($size-mobile) {
      margin-bottom: calc(var(--scale) * 20);

      span {
        display: block;
      }
    }
  }

  &__buttons {
    display: flex;
    gap: calc(var(--scale) * 8);

    &::-webkit-scrollbar {
      width: 0;
    }

    @include media-down($size-tablet) {
      gap: calc(var(--scale) * 4);
      overflow-x: auto;
      width: calc(100% + var(--scale) * 32);
      margin-left: calc(-1 * var(--scale) * 16);
      padding-inline: calc(var(--scale) * 16);
    }

    @include media-down($size-mobile) {
      width: calc(100% + calc(var(--scale) * 24));
      margin-left: calc(-1 * var(--scale) * 12);
      padding-inline: calc(var(--scale) * 12);
    }

    button {
      padding: calc(var(--scale) * 32) calc(var(--scale) * 54);
      background-color: #FFFFFF;
      border-radius: calc(var(--scale) * 200);
      transition: 200ms;

      font-family: inherit;
      font-weight: 500;
      font-size: calc(var(--scale) * 32);
      line-height: 110%;
      color: #A7ABAC;

      @include media-down($size-tablet) {
        padding: calc(var(--scale) * 16) calc(var(--scale) * 22);
        font-size: calc(var(--scale) * 18);
        white-space: nowrap;
      }

      &:hover {
        color: #5237E6;
      }

      &.active {
        background-color: #5237E6;
        color: #FFFFFF;
      }
    }
  }

  &__programs {
    margin-top: calc(var(--scale) * 29);
    display: grid;
    grid-template-columns: repeat(4, calc(var(--scale) * 445));
    gap: calc(var(--scale) * 20);

    @include media-down($size-tablet) {
      margin-top: calc(var(--scale) * 16);
      grid-template-columns: repeat(2, calc(50% - var(--scale) * 4));
      gap: calc(var(--scale) * 8);
    }

    @include media-down($size-mobile) {
      grid-template-columns: 100%;
    }
  }

  &__program {
    height: calc(var(--scale) * 300);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: calc(var(--scale) * 24) calc(var(--scale) * 30);
    gap: calc(var(--scale) * 8);
    position: relative;
    background-color: #FFFFFF;
    background-position: right bottom;
    background-repeat: no-repeat;
    border-radius: calc(var(--scale) * 40);
    cursor: pointer;

    @include media-down($size-tablet) {
      height: calc(var(--scale) * 180);
      padding: calc(var(--scale) * 22) calc(var(--scale) * 16) calc(var(--scale) * 16);
      border-radius: calc(var(--scale) * 32);
      background-size: auto 85%;
    }

    h4 {
      font-weight: 500;
      font-size: calc(var(--scale) * 48);
      line-height: 110%;
      letter-spacing: -0.02em;
      color: #02120F;

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 28);
      }
    }

    p {
      font-weight: 400;
      font-size: calc(var(--scale) * 20);
      line-height: 130%;
      color: #A7ABAC;

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 16);
        letter-spacing: -0.02em;
      }
    }

    button {
      margin-top: auto;
      transition: 200ms;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(var(--scale) * 42);
      height: calc(var(--scale) * 42);
      border-radius: calc(var(--scale) * 12);

      @include media-down($size-tablet) {
        width: auto;
        padding-inline: calc(var(--scale) * 16);
        gap: calc(var(--scale) * 10);
        border-radius: calc(var(--scale) * 16);
        flex-shrink: 0;
        flex-direction: row-reverse;
      }

      span {
        display: none;

        font-weight: 500;
        font-size: calc(var(--scale) * 18);
        line-height: 120%;

        @include media-down($size-tablet) {
          display: block;
        }
      }

      img {
        width: calc(var(--scale) * 10);
      }

      &.main-programs__program_arrow-pink {
        background-color: #FFCEE2;

        span {
          color: #8D176C;
        }

        img {
          filter: invert(15%) sepia(38%) saturate(4712%) hue-rotate(296deg) brightness(102%) contrast(100%);
        }
      }

      &.main-programs__program_arrow-green {
        background-color: #E3FFF7;

        span {
          color: #01412E;
        }

        img {
          filter: invert(18%) sepia(19%) saturate(3358%) hue-rotate(125deg) brightness(95%) contrast(99%);
        }
      }

      &.main-programs__program_arrow-blue {
        background-color: #CEF9FF;

        span {
          color: #0A4183;
        }

        img {
          filter: invert(16%) sepia(34%) saturate(5767%) hue-rotate(204deg) brightness(92%) contrast(92%)
        }
      }

      &.main-programs__program_arrow-violet {
        background-color: #E6CEFF;

        span {
          color: #5237E6;
        }

        img {
          filter: invert(31%) sepia(93%) saturate(6296%) hue-rotate(248deg) brightness(91%) contrast(98%);
        }
      }

      &.main-programs__program_arrow-yellow {
        background-color: #FFF0D1;

        span {
          color: #FF5319;
        }

        img {
          filter: invert(44%) sepia(42%) saturate(4697%) hue-rotate(349deg) brightness(99%) contrast(103%);
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        button img {
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
        }

        .main-programs__program_arrow-pink {
          background-color: #F199CE
        }

        .main-programs__program_arrow-green {
          background-color: #22AE9D;
        }

        .main-programs__program_arrow-blue {
          background-color: #0B72BE;
        }

        .main-programs__program_arrow-violet {
          background-color: #846EFB;
        }

        .main-programs__program_arrow-yellow {
          background-color: #FF9C12;
        }
      }
    }

    &_discount {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      right: calc(var(--scale) * 10);
      top: calc(var(--scale) * 20);

      @include media-down($size-tablet) {
        right: calc(var(--scale) * 2);
        top: calc(var(--scale) * 6);
      }

      span {
        padding: calc(var(--scale) * 8) calc(var(--scale) * 14) calc(var(--scale) * 10);
        background-color: #ED131C;
        border-radius: calc(var(--scale) * 40);
        transform: rotate(5deg);
        font-size: calc(var(--scale) * 20);
        line-height: 130%;
        color: #FFFFFF;

        @include media-down($size-tablet) {
          font-size: calc(var(--scale) * 14);
        }
      }

      p {
        max-width: calc(var(--scale) * 129);
        transform: rotate(-4.5deg);
        text-align: center;
        font-size: calc(var(--scale) * 20);
        line-height: 130%;
        color: #8D1717;

        @include media-down($size-tablet) {
          font-size: calc(var(--scale) * 14);
          max-width: calc(var(--scale) * 100);
        }
      }
    }
  }

  &__form {
    padding: calc(var(--scale) * 24) calc(var(--scale) * 20) calc(var(--scale) * 20);
    grid-column: 4/5;
    grid-row: 1/4;
    height: fit-content;
    background-image: url("/v2/main/programs/form-bg.webp");
    background-size: cover;
    border-radius: calc(var(--scale) * 60);

    @include media-down($size-tablet) {
      grid-row: auto;
      grid-column: auto;
      padding: calc(var(--scale) * 20) calc(var(--scale) * 8) calc(var(--scale) * 8);
      border-radius: calc(var(--scale) * 32);
      background-image: url("/v2/main/programs/form-bg-mob.webp");
    }

    p {
      margin-bottom: calc(var(--scale) * 30);
      padding-inline: calc(var(--scale) * 10);

      font-weight: 500;
      font-size: calc(var(--scale) * 32);
      line-height: 110%;
      color: #FFFFFF;

      @include media-down($size-tablet) {
        margin-bottom: calc(var(--scale) * 18);
        font-size: calc(var(--scale) * 28);
      }
    }

    .form__input {
      margin-bottom: calc(var(--scale) * 8);

      @include media-down($size-tablet) {
        display: none;
      }

      &:last-of-type {
        margin-bottom: calc(var(--scale) * 31);
      }

      ::v-deep(.phone-field__wrap),
      ::v-deep(input) {
        width: 100%;
        height: calc(var(--scale) * 88);
        border: none;

        font-weight: 500;
        font-size: calc(var(--scale) * 26);
        line-height: 120%;
        color: #A7ABAC;

        &::placeholder {
          color: inherit;
        }
      }
    }

    span {
      display: block;
      margin-bottom: calc(var(--scale) * 39);
      padding-inline: calc(var(--scale) * 10);

      font-weight: 400;
      font-size: calc(var(--scale) * 20);
      line-height: 130%;
      color: #FFFFFF;

      @include media-down($size-tablet) {
        display: none;
      }

      a {
        color: inherit;
        text-decoration-line: underline;
        opacity: 0.5;
      }
    }

    .m-btn {
      width: 100%;
      padding: calc(var(--scale) * 44) calc(var(--scale) * 16);
      background-color: #8D176C;
      border-radius: calc(var(--scale) * 40);
      background-blend-mode: soft-light, normal;

      font-weight: 500;
      font-size: calc(var(--scale) * 32);
      line-height: 110%;
      color: #FFFFFF;

      @include media-down($size-tablet) {
        padding: calc(var(--scale) * 30);
        border-radius: calc(var(--scale) * 24);
        font-size: calc(var(--scale) * 18);
      }
    }

    .main-programs__form_submit {
      @include media-down($size-tablet) {
        display: none;
      }
    }

    .main-programs__form_btn {
      display: none;

      @include media-down($size-tablet) {
        display: block;
      }
    }
  }
}

</style>
